import React from "react"
import Layout from "../retech/components/layout/Layout"
import { InputGroup, FormControl } from "react-bootstrap"
import { Helmet, Parent, Child } from "react-helmet"

import Media from "../retech/components/MediaCenter/Index"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSearch,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons"
import { Link, graphql } from "gatsby"
export default function ServicePage({ data }) {
  const metadata = data.metadata.nodes
  const contactinfo = data.contactinfo.nodes
  const widget1 = data.widget1.nodes
  const widget2 = data.widget2.nodes
  const widgetlinks = data.widgetlinks.nodes
  const categoryservice = data.categoryservice.nodes
  const [searchTerm, setSearchTerm] = React.useState("")
  const [searchResults, setSearchResults] = React.useState([])
  const handleChange = event => {
    setSearchTerm(event.target.value)
  }
  React.useEffect(() => {
    const results = categoryservice.filter(categoryservice =>
      categoryservice.ServiceItemTitle.toLowerCase().includes(searchTerm)
    )
    setSearchResults(results)
  }, [searchTerm])

  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  return (
    <Layout>
      <Helmet>
        <title>Services</title>

        <meta
          name="description"
          content={data.metadata.nodes[0].ServicesMetaDescription}
        />
        <meta
          name="keywords"
          content={data.metadata.nodes[0].Serviceskeywords}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta
          property="og:title"
          content={data.metadata.nodes[0].ServicesMetaTitle}
        />
        <meta
          property="og:description"
          content={data.metadata.nodes[0].ServicesMetaDescription}
        />
        <meta property="og:image" content={urlimage + "/logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta
          property="twitter:title"
          content={data.metadata.nodes[0].ServicesMetaTitle}
        />
        <meta
          property="twitter:description"
          content={data.metadata.nodes[0].ServicesMetaDescription}
        />
        <meta property="twitter:image" content={urlimage + "/logo.svg"} />
      </Helmet>
      <div className="container faq">
        <div className="col-md-12 my-5 py-5 border-bottom bottom-service" >
          <div className="row">
            <div className="col-md-7 mb-5">
              <h1 className="font-weight-bolder">Retech Services</h1>
              <p className="product-par">
                Retech’s services extend beyond the manufacturing and installation of our specialty equipment.
              </p>
            </div>
            <div className="col-md-5 align-self-center search-loup mb-5">
              <InputGroup.Append className="shadow-none has-search">
                <FormControl
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="Search"
                  className="shadow-none bg-light border-0"
                  value={searchTerm}
                  onChange={handleChange}
                />
                <span className=" form-control-feedback">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </InputGroup.Append>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row py-5">
            {searchResults?.map((node, index) => {
              return (
                <>
                  <div className="col-md-4 mb-4 section-link" key={index}>
                    <div className="pickgradient w-100">
                      <Link

                        to={"/services/" + node.ServiceItemCTALink}
                      >
                        <img
                          src={node.ServiceItemImage}
                          alt={node.ServiceItemImage}
                          className="service-bg w-100"
                          height="365px"
                          style={{ objectFit: "cover" }}
                        />
                        <p className="location">
                          {node.ServiceItemTitle}
                          <br></br>
                        </p>
                      </Link>
                      <p className="parallelogram mb-4 mr-3 active-filter">
                        <Link
                          className="text-dark "
                          to={"/services/" + node.ServiceItemCTALink}
                        >
                          MORE
                        </Link>
                      </p>
                    </div>
                  </div>
                </>
              )
            })}
            {searchResults.filter(searchResults =>
              searchResults.ServiceItemTitle.toLowerCase().includes(searchTerm)
            ).length === 0 && <p>No results found, please try a different search query or return <a href='/'>Home</a></p>}
          </div>
        </div>
      </div>
      <div className="container-fluid alternative-bg bg-light py-5 px-0">
        <div className="container">
          {contactinfo.map((node, index) => {
            return (
              <>
                <div className="col-md-12">
                  <div className="row py-5">
                    <div
                      className="col-md-6 d-none d-sm-block background-des"
                      key={index}
                    >
                      <img
                        src={node.ImagewithContactInfoWidgetImage}
                        className="w-100"
                        alt="background"
                      />
                    </div>
                    <div
                      className="col-xxl-6 col-lg-6 col-md-6 ml-auto pt-4"
                      key={index}
                    >
                      <h4 className="mb-4 font-weight-bolder">
                        {node.ImagewithContactInfoWidgetTilte}
                      </h4>
                      <p className="product-par">
                        <small>
                          {node.ImagewithContactInfoWidgetDescription}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-7 col-lg-8 col-md-10 service-section d-block d-sm-none Service-mobile" style={{
                  backgroundImage:
                    "url(" +
                    node.ImagewithContactInfoWidgetImage +
                    ")",
                }}>
                  <div className="bg-white py-5 px-3 Service">
                    <div className="row Engineering text-center">
                      <div className="col-md-8">
                        <h5 className="font-weight-bold">
                          {node.ContactDescription}
                        </h5>
                      </div>
                      <div className="col-md-4 px-5 pt-0 mt-0">
                        <h6 className="font-weight-bolder">Contact us</h6>
                        <p className="text-muted mb-3">
                          <small>
                            For detailed information regarding capabilities at
                            Retech, please contact us.
                          </small>
                        </p>
                        <div
                          to={node.ContactCTALink}
                          className="parallelogram mb-4 text-center mx-auto"
                        >
                          <Link
                            to={node.ContactCTALink}
                            className="text-dark font-weight-bold"
                          >
                            {node.ContactCTAText}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-7 col-lg-8 col-md-10 service-section  d-none d-sm-block">
                  <div className="bg-white py-5 px-3 Service">
                    <div className="row Engineering">
                      <div className="col-md-7 border-right pl-5">
                        <h5 className="font-weight-bold ">
                          We believe there is not a more experienced team in the
                          world to design, manufacture and install specialized
                          thermal vacuum processing equipment
                        </h5>
                      </div>
                      <div className="col-md-5 text-left pl-4">
                        <h6 className="font-weight-bolder">Contact us</h6>
                        <p className="mb-3">
                          <small>
                            For detailed information regarding capabilities at
                            Retech, please contact us.
                          </small>
                        </p>
                        <div
                          to={node.ContactCTALink}
                          className="parallelogram mb-4"
                        >
                          <Link
                            to={node.ContactCTALink}
                            className="text-dark font-weight-bold"
                          >
                            {node.ContactCTAText}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
      <div className="container-fluid alternative-bg bg-white py-5 px-0">
        <div className="container">
          <div className="col-md-12">
            <div className="row py-5">
              {widget1.map((node, index) => {
                return (
                  <>
                    <div
                      className="col-xxl-6 col-lg-6 col-md-6 ml-auto pt-4"
                      key={index}
                    >
                      <h4 className="mb-4 font-weight-bolder">
                        {node.ImagewithTextWidget1Title}
                      </h4>
                      <p className="product-par">
                        <small>{node.ImagewithTextWidget1Description}</small>
                      </p>
                    </div>
                    <div
                      className="col-md-6 d-none d-sm-block  background-des"
                      key={index}
                    >
                      <img
                        src={node.ImagewithTextWidget1Image}
                        className="w-100"
                        alt="background"
                      />
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid alternative-bg bg-light py-5 px-0">
        <div className="container">
          <div className="col-md-12">
            <div className="row py-5">
              {widget2.map((node, index) => {
                return (
                  <>
                    <div className="col-md-6 background-des" key={index}>
                      <img
                        src={node.ImagewithTextWidget2Image}
                        className="w-100"
                        alt="background"
                      />
                    </div>
                    <div
                      className="col-xxl-6 col-lg-6 col-md-6 ml-auto pt-4"
                      key={index}
                    >
                      <h4 className="mb-4 font-weight-bolder">
                        {node.ImagewithTextWidget2Title}
                      </h4>
                      <p className="product-par">
                        <small>{node.ImagewithTextWidget2Description}</small>
                      </p>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-md-12 border-bottom pt-3">
          <div className="row py-5">
            {widgetlinks.map((node, index) => {
              return (
                <>
                  <div className="col-md-7 mx-auto text-center">
                    <h3 className="mb-4 font-weight-bolder">
                      {node.TextwithCTALinkWidgetTitle}
                    </h3>
                    <p className="product-par">
                      {" "}
                      {node.TextwithCTALinkWidgetDescription}
                    </p>
                    <Link
                      to={node.TextwithCTALinkWidgetCTALink}
                      className="font-weight-bolder see-all justify-content-center"
                    >
                      <p>{node.TextwithCTALinkWidgetCTAText}</p>
                      <FontAwesomeIcon
                        className="ml-3"
                        icon={faChevronCircleRight}
                      />{" "}
                    </Link>
                  </div>
                </>
              )
            })}
          </div>
        </div>
        <div className="col-md-12 py-5 Media ">
          <Media />
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
      query {
        categoryservice: allServiceItemJson(
      sort: {fields: ServiceItemTitle, order: DESC }
      ) {
        nodes {
        ServiceItemCTALink
        ServiceItemTitle
      ServiceItemImage
      }
    }
      widgetlinks: allServicesJson(
      filter: {TextwithCTALinkWidgetTitle: {ne: null } }
      ) {
        nodes {
        TextwithCTALinkWidgetCTALink
        TextwithCTALinkWidgetCTAText
      TextwithCTALinkWidgetDescription
      TextwithCTALinkWidgetTitle
      }
    }
      widget2: allServicesJson(
      filter: {ImagewithTextWidget2Title: {ne: null } }
      ) {
        nodes {
        ImagewithTextWidget2Description
        ImagewithTextWidget2Image
      ImagewithTextWidget2Title
      }
    }
      widget1: allServicesJson(
      filter: {ImagewithTextWidget1Title: {ne: null } }
      ) {
        nodes {
        ImagewithTextWidget1Description
        ImagewithTextWidget1Image
      ImagewithTextWidget1Title
      }
    }
      contactinfo: allServicesJson(
      filter: {ImagewithContactInfoWidgetTilte: {ne: null } }
      ) {
        nodes {
        ContactCTALink
        ContactCTAText
      ContactDescription
      ImagewithContactInfoWidgetTilte
      ImagewithContactInfoWidgetDescription
      ImagewithContactInfoWidgetImage
      }
    }
      metadata: allMetadataJson(filter: {ServicesMetaTitle: {ne: null } }) {
        nodes {
        ServicesMetaDescription
        ServicesMetaTitle
      Serviceskeywords
      }
    }
  }
      `
